<template>
  <div class="assess-title">
    <!-- title -->
    <el-row
      :gutter="20"
      class="row"
    >
      <el-col :span="4">
        <span
          class="back"
          @click="$router.go(-1)"
        >
          <i class="el-icon-arrow-left" /> 返回
        </span>
      </el-col>
      <el-col
        :span="16"
        class="text-center title"
      >
        <template v-if="$route.query.checkModal">
          {{ titleChildName }}
        </template>
        <Step v-else />
      </el-col>
      <el-col
        :span="4"
        class="text-right"
      >
        <el-button
          v-if="!isDownload"
          round
          class="linear-btn right"
          @click="exitAssess"
        >
          退出评测
        </el-button>
        <el-button
          v-else
          round
          class="linear-btn right"
          @click="dowloadReport"
        >
          下载报告
        </el-button>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import Vue from 'vue';
import vueToPdf from 'vue-to-pdf';

Vue.use(vueToPdf);
export default {
  name: 'AccessTitle',
  components: {
    Step: () => import('./step.vue')
  },
  computed: {
    isDownload() {
      return this.$route.name === 'assess.report';
    },
    titleChildName() {
      return this.$store.state.report.titleChildName;
    }
  },
  methods: {
    exitAssess() {
      this.$router.push('/');
    },
    dowloadReport() {
      const report = document.querySelector('#report');
      this.$PDFSave(report, '测评报告');
    }
  },
};
</script>

<style lang="less" scoped>
.row{
  height: 40px;
  line-height: 40px;
}
.back{
  font-size: 16px;
  color: #000;
  cursor: pointer;
  padding: 10px;
}
.title{
  font-size: 18px;
  font-weight: 600;
  color: #000;
}
.right{
  padding: 0 40px;
}
</style>
